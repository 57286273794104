import { isObject } from "./utils";

// LOG LEVEL
const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL * 1; // 3: debug, 2: info, 1: warn, 0: error

// Log form
const printLog = (param) => {
  if (param instanceof Error) return param.toString();

  if (!isObject(param)) return param;

  const values = Object.values(param);
  const allUndefined = values.every((el) => el === undefined);

  if (allUndefined) return param;

  const traceId = param.traceId === undefined ? "" : `[${param.traceId}] `;
  const func = param.func === undefined ? "" : `[${param.func}] `;
  const title = param.title === undefined ? "" : param.title;
  const msg = param.msg;
  const code = param.code;
  const data = param.data;

  let detail = {};

  if (msg === undefined && code === undefined && data === undefined) {
    detail = null;
  } else {
    if (msg !== undefined) detail.msg = msg;
    if (code !== undefined) detail.code = code;
    if (data !== undefined) detail.data = data;
  }

  if (detail?.msg instanceof Error) detail.msg = detail.msg.toString();
  if (detail?.data instanceof Error) detail.data = detail.data.toString();

  const output =
    detail === null
      ? `${traceId}${func}${title}`
      : `${traceId}${func}${title} =>\r\n${JSON.stringify(detail, null, 4)}`;

  return output;
};

const log = {
  debug: (msg1, msg2) => {
    msg2 = msg2 === undefined ? "" : msg2;
    if (typeof msg2 === "object") msg2 = JSON.stringify(msg2, null, 2);
    msg1 = `${msg1}
${msg2}`;
    if (3 <= LOG_LEVEL)
      console.log(`%c(debug)%c ${printLog(msg1)}`, "color:#2196F3", "");
  },
  info: (msg1, msg2) => {
    msg2 = msg2 === undefined ? "" : msg2;
    if (typeof msg2 === "object") msg2 = JSON.stringify(msg2, null, 2);
    msg1 = `${msg1}
${msg2}`;
    if (2 <= LOG_LEVEL)
      console.log(`%c(info)%c ${printLog(msg1)}`, "color:#4CAF50", "");
  },
  warn: (msg1, msg2) => {
    msg2 = msg2 === undefined ? "" : msg2;
    if (typeof msg2 === "object") msg2 = JSON.stringify(msg2, null, 2);
    msg1 = `${msg1}
${msg2}`;
    if (1 <= LOG_LEVEL)
      console.log(`%c(warn)%c ${printLog(msg1)}`, "color:#FFC107", "");
  },
  error: (msg1, msg2) => {
    if (typeof msg2 === "object") msg2 = JSON.stringify(msg2, null, 2);
    msg1 = `${msg1}
${msg2}`;
    if (0 <= LOG_LEVEL)
      console.log(`%c(error)%c ${printLog(msg1)}`, "color:#F44336", "");
  },
};

export default log;
