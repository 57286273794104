import { ReactComponent as IconMenuPlayground } from "../../assets/images/icons/icon-menu-playground.svg";
import { ReactComponent as IconMenuAIKMS } from "../../assets/images/icons/icon-menu-ai_kms.svg";
import { ReactComponent as IconMenuAIBuilder } from "../../assets/images/icons/icon-menu-ai_builder.svg";
import { ReactComponent as IconMenuHome } from "../../assets/images/icons/icon-menu-home.svg";
import { ReactComponent as IconLogout } from "../../assets/images/icons/icon-menu-home.svg";
import { ContentContext } from "../../pages/context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import defineConfig from "../../config/defineConfig";
import useStorage from "../../hooks/useStorage";
import usersService from "../../services/users";

const MenuList = [
  {
    icon: <IconMenuHome />,
    title: defineConfig.ContentList.search.title,
    url: defineConfig.ContentRoute.search,
    micro: false,
    // url: "/app/selection",
  },
  {
    icon: <IconMenuPlayground />,
    title: defineConfig.ContentList.playground.title,
    url: defineConfig.ContentRoute.playground,
    micro: true,
  },
  {
    icon: <IconMenuAIKMS />,
    title: defineConfig.ContentList.kms.title,
    url: defineConfig.ContentRoute.kms,
    micro: true,
  },
  // {
  //   icon: <IconMenuAIBuilder />,
  //   title: defineConfig.ContentList.builder.title,
  //   url: defineConfig.ContentRoute.builder,
  //   micro: true,
  // },
  {
    icon: <IconLogout />,
    title: "Logout",
    url: "/logout",
    micro: false,
  },
];

function PopupMenu({ closeMenu }) {
  const navigate = useNavigate();
  const { setStorageItem, removeStorageItem } = useStorage();
  const {
    state: { contentType, userInfo },
    handleContentType,
  } = useContext(ContentContext);

  const MoveMenu = (value, micro) => {
    if (value === "logout") {
      usersService.logout().then((response) => {
        console.log("logout response >>>> ", response);
      });
    }
    removeStorageItem("path");
    if (defineConfig.isServer) {
      if (micro && userInfo.name === "") {
        navigate(defineConfig.Route.LOGIN);
      } else {
        setStorageItem("contentType", value);
        handleContentType(value);
        closeMenu();
        value !== defineConfig.ContentRoute.search.split("/")[1] &&
          window.location.reload();
      }
    } else {
      setStorageItem("contentType", value);
      handleContentType(value);
      closeMenu();
      value !== defineConfig.ContentRoute.search.split("/")[1] &&
        window.location.reload();
    }
  };

  return (
    <div className="relative">
      <div className="fixed z-[99] right-[5.5%]">
        <div className="relative z-[2] left-[45%]">
          <div className="w-0 h-0 border-l-[12px] border-r-[12px] border-b-[18px] border-l-transparent border-r-transparent border-b-[#ffffff] drop-shadow-[4px_-3.6px_6px_rgba(0,0,0,0.2)]" />
        </div>
        <div className="relative z-[1] w-[240px] h-full bg-[#FFFFFF] rounded-[10px] drop-shadow-[4px_0px_6px_rgba(0,0,0,0.2)]">
          <div className="px-[16px] py-[14px] space-y-[8px]">
            {MenuList.map((menu, idx) => {
              return (
                <div
                  key={idx}
                  className="flex flex-row z-[5] items-center space-x-[20px] hover:bg-[#F5F5F5] hover:rounded-[10px] cursor-pointer"
                  onClick={() => MoveMenu(menu.url.split("/")[1], menu.micro)}
                >
                  <div className="w-[38px] h-[38px]">{menu.icon}</div>
                  <div
                    className={`text-[18px] leading-[20px] ${
                      contentType === menu.url.split("/")[1]
                        ? "text-[#396CFE] font-[700]"
                        : "text-[#010101] font-[500]"
                    }`}
                  >
                    {menu.title}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className="fixed z-[9] top-0 left-0 w-screen h-screen"
        onClick={closeMenu}
      />
    </div>
  );
}

export default PopupMenu;
