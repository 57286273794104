import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
// import { RecoilRoot } from "recoil";
import { ConfigProvider } from "antd";

import router from "./Router";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      // global
      token: {
        colorPrimary: "#229e2d",
        // colorBgContainer: "#f6ffed",
      },
      components: {
        // InputNumber: {},
      },
    }}
  >
    {/* <RecoilRoot> */}
    <RouterProvider router={router} />
    {/* </RecoilRoot> */}
  </ConfigProvider>,
  // </React.StrictMode>
);
