function useStorage() {
  const storage = window.localStorage;

  const setStorageItem = (key, value) => {
    if (value === undefined || value === null) {
      return;
    } else {
      storage.setItem(key, value);
    }
  };

  const getStorageItem = (key) => {
    return storage.getItem(key) || -1;
  };

  const getStorage = () => {
    return storage;
  };

  const removeStorageItem = (key) => {
    if (key === "clear()") {
      storage.clear();
    } else {
      storage.removeItem(key);
    }
  };

  return {
    getStorage,
    setStorageItem,
    getStorageItem,
    removeStorageItem,
  };
}

export default useStorage;
