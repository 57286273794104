import ProfileImg from "../../assets/images/test-profile.png";

function Profile({ userInfo, textColor }) {
  return (
    <div className="flex flex-row space-x-[10px] items-center">
      <img
        src={userInfo.imgSrc || ProfileImg}
        alt="profile"
        width={40}
        height={40}
        className="rounded-full"
      />
      <div
        className={`w-[80px] text-[16px] leading-[20px] font-[700] ${textColor} truncate`}
      >
        {userInfo.name}
      </div>
    </div>
  );
}

export default Profile;
