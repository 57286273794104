import axios from "axios";

import log from "../utils/logger";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_GATEWAY_PROTOCOL}://${process.env.REACT_APP_API_GATEWAY_HOST}:${process.env.REACT_APP_API_GATEWAY_PORT}/api`,
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
  withCredentials: true,
});

const reqRest = async (config) => {
  try {
    const response = await axiosInstance(config);
    const { data } = response;

    log.debug(`Server response =>`, {
      status: response.status,
      statusText: response.statusText,
      data,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

export { reqRest };
