import { useEffect, useMemo, useRef, useState } from "react";
import defineConfig from "../config/defineConfig";

const initState = {
  src: "",
  title: "micro-app",
  width: "100%",
  height: "100%",
  className: "",
};

function useMicroApp(setStorageItem) {
  const iframeRef = useRef(null);
  const [iframeSrc, setIframeSrc] = useState(initState.src);
  // const [iframeTitle, setIframeTitle] = useState(initState.title);
  // const [iframeWidth, setIframeWidth] = useState(initState.width);
  // const [iframeHeight, setIframeHeight] = useState(initState.height);
  // const [iframeClassName, setIframeClassName] = useState(initState.className);

  // const [iframeData, setIframeData] = useState(null);

  const IframeTemplate = () => {
    return (
      <iframe
        ref={iframeRef}
        src={iframeSrc}
        title={initState.title}
        width={initState.width}
        height={initState.height}
        className={initState.className}
      />
    );
  };

  // const sendIframeMessage = (message, url = null) => {
  //   if (url) {
  //     iframeRef.current.contentWindow.postMessage(message, url);
  //   } else {
  //     iframeRef.current.contentWindow.postMessage(message, iframeSrc);
  //   }
  // };

  useEffect(() => {
    console.log("iframe >>>> ", iframeSrc);
    if (iframeSrc !== "") {
      window.addEventListener("message", (e) => {
        if (defineConfig.ACCESS_LIST.includes(e.origin) !== -1) {
          if (e.data.value) {
            console.log("iframe event >>>> ", e.data);
            setStorageItem("path", e.data.value);
          }
        }
      });
    }

    return () => {
      window.removeEventListener("message", (e) => {
        console.log("remove iframe event >>>> ", e);
      });
    };
  }, [iframeSrc]);

  // useEffect의 addEventListener를 iframeSrc의 값을 통해 활성화
  // useMemo를 통해서 iframeSrc의 값이 변경된 경우에만 리렌더링이 되도록 설정
  return useMemo(
    () => ({
      IframeTemplate,
      iframeSrc,
      setIframeSrc,
    }),
    [iframeSrc],
  );
}

export default useMicroApp;
