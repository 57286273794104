import defineConfig from "../config/defineConfig";

const isObject = (value) =>
  typeof value === "object" && value !== null && !Array.isArray(value);

const delay = (func, time = defineConfig.SET_LOADING_TIMEOUT) => {
  let sto = setTimeout(func, time);
  return () => clearTimeout(sto);
};

export { isObject, delay };
