import { reqRest } from "./common";

const login = async ({ emailId, password }) => {
  const config = {
    method: "post",
    url: "/login",
    data: {
      userInfo: {
        id: emailId,
        password,
      },
    },
  };

  return reqRest(config);
};

const logout = async () => {
  const config = {
    method: "post",
    url: "/logout",
  };

  return reqRest(config);
};

const signUp = async ({
  userName,
  emailId,
  userRole,
  password,
  contact,
  department,
}) => {
  const config = {
    method: "post",
    url: "/sign-up",
    data: {
      userInfo: {
        id: emailId,
        name: userName,
        role: userRole,
        password,
        contact,
        department,
      },
    },
  };

  return reqRest(config);
};

const isLoggedIn = async () => {
  const config = {
    method: "get",
    url: "/users/check/login",
  };

  return reqRest(config);
};

export default {
  login,
  logout,
  signUp,
  isLoggedIn,
};
